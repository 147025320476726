<template>
  <div>
    <el-form-item
      v-for="item in number"
      class="solt-content"
      :key="'b' + item"
      :label="`${labelTitle}${item}`"
      :rules="[{ required: true, message: `请输入当前${labelTitle}` }]"
    >
      <el-input v-model="data[item]"></el-input>
    </el-form-item>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      default: 1
    },
    data: {
      type: Array,
      default: () => {
        return [];
      }
    },
    labelTitle: {
      type: String
    }
  },
  data() {
    return {};
  },
  created() {
    console.log(this.data);
  }
};
</script>

<style lang="scss">
.solt-content {
  width: 50%;
  display: inline-block;
  box-sizing: border-box;
  padding: 0 10px;

  .el-form-item__content {
    position: static;
  }
}
</style>
