import request from '@/utils/request';

export function queryLeadSealList(params) {
  return request({
    url: '/Typein_seal/data',
    method: 'get',
    params
  });
}

export function addSealNum(params) {
  return request({
    url: '/Typein_seal/add_seal_num',
    method: 'get',
    params
  });
}

export function delSealNum(params) {
  return request({
    url: '/Typein_seal/del',
    method: 'get',
    params
  });
}

export function exportCheck(params) {
  return request({
    url: '/tanker_check_up/export',
    method: 'get',
    responseType: 'blob',
    params: {
      ...params,
      type: '1'
    }
  });
}

export function getTankerList(params) {
  return request({
    url: '/Typein_seal/get_tanker_list',
    method: 'get',
    params
  });
}

export function queryMeasureList(params) {
  return request({
    url: '/Typein_seal/measur_data',
    method: 'get',
    params
  });
}

export function addMeasureNum(params) {
  return request({
    url: '/Typein_seal/measur_add',
    method: 'get',
    params
  });
}

export function delMeasureNum(params) {
  return request({
    url: '/Typein_seal/measur_del',
    method: 'get',
    params
  });
}
