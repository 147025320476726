// import { getTankerList } from '@/api/lead_seal_number';
// import { getTax } from './index.vue';
// import { getGasData } from '@/api/global';

export const columns = [
  {
    title: '序号',
    key: 'index',
    width: 64,
    scopedSlots: { customRender: 'index' }
  },
  {
    title: '加油站名称',
    key: 'site_name',
    minWidth: 150
  },
  {
    title: '加油机名称',
    key: 'tanker_name',
    minWidth: 80
  },
  {
    title: '监管设备编号',
    key: 'tax_control_num',
    minWidth: 150
  },
  {
    title: '处理器数',
    key: 'num_1',
    minWidth: 100
  },
  {
    title: '编码器数',
    key: 'num_2',
    minWidth: 100
  },
  {
    title: '操作',
    key: 'actions',
    width: 220,
    extraType: 'button',
    scopedSlots: { customRender: 'actions' }
  }
];

export const formDesc = {
  // site_id: {
  //   type: 'select',
  //   label: '加油站名称',
  //   layout: 24,
  //   rules: [{ required: true, message: '请选择加油站' }],
  //   prop: { text: 'name', value: 'id' },
  //   options: async () => {
  //     const result = await getGasData();
  //     console.log(111);
  //     if (result && result.code === 0) {
  //       return result.data;
  //     } else {
  //       return [];
  //     }
  //   },
  //   attrs: {
  //     filterable: true
  //   }
  // },
  // tanker_id: {
  //   type: 'select',
  //   label: '加油机名称',
  //   optionsLinkageFields: ['site_id'],
  //   layout: 24,
  //   rules: [{ required: true, message: '请输入加油机' }],
  //   prop: { text: `tanker_name`, value: 'tanker_id' },
  //   options: async data => {
  //     if (data.site_id) {
  //       const result = await getTankerList({ site_id: data.site_id });
  //       console.log('222', data.tanker_id);
  //       if (data.id) {

  //       }
  //       if (result && result.code === 0) {
  //         return result.data;
  //       } else {
  //         return [];
  //       }
  //     } else {
  //       return [];
  //     }
  //   },
  //   attrs: {
  //     filterable: true
  //   }
  // },

  site_id: {
    type: 'input',
    layout: 24,
    rules: [{ required: true, message: '请选择加油站' }],
    label: '加油站名称'
  },

  tanker_id: {
    type: 'input',
    layout: 24,
    rules: [{ required: true, message: '请输入加油机' }],
    label: '加油机名称'
  },

  num_1: {
    type: 'select',
    label: '处理器数',
    default: 1,
    options: [1, 2, 3, 4],
    layout: 24,
    rules: [{ required: true, message: '请输入序列号1' }]
    // attrs: {
    //   clearable: true
    // }
  },
  seal_1: {
    type: 'input',
    layout: 12,
    label: '处理器1'
  },
  num_2: {
    type: 'select',
    label: '编码器数',
    default: 1,
    options: [1, 2, 3, 4, 5, 6, 7, 8],
    layout: 24,
    rules: [{ required: true, message: '请输入序列号1' }]
    // attrs: {
    //   clearable: true
    // }
  },
  seal_2: {
    type: 'input',
    layout: 12,
    label: '编码器1'
  }

  // seal_1: {
  //   type: 'input',
  //   label: '序列号1',
  //   layout: 24,
  //   rules: [{ required: true, message: '请输入序列号1' }]
  // },
  // seal_2: {
  //   type: 'input',
  //   label: '序列号2',
  //   layout: 24,
  //   rules: [{ required: true, message: '请输入序列号2' }]
  // }
};
